import axios from "axios";
import "./FourthStep.css";
import cxl from "../../Assets/cxl.png";

const FileDownloadButton = () => {
   const API = "https://api-comcheck-7f7s.onrender.com/generate-xml/";

   const handleDownload = () => {
      axios
         .get(API, {
            responseType: "blob",
         })
         .then((response) => {
            const blob = new Blob([response.data], {
               type: response.headers["content-type"],
            });

            const fileURL = URL.createObjectURL(blob);

            const downloadLink = document.createElement("a");

            downloadLink.href = fileURL;

            downloadLink.download = "file.xml";

            document.body.appendChild(downloadLink);

            downloadLink.click();

            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(fileURL);
         })
         .catch((error) => {
            console.error("Error fetching or processing the file:", error);
         });
   };

   return (
      <>
         <div className="download--content d-flex flex-column align-items-center">
            <img src={cxl} className="upload--img opacity-75 mb-4" />
            <button
               onClick={handleDownload}
               className="download--btn rounded-pill"
            >
               Download CXL (XML)
            </button>
         </div>
      </>
   );
};

export default FileDownloadButton;
