import { Nav } from "react-bootstrap";
import "./Navbar.css";
import { Link, NavLink } from "react-router-dom";
import logo from "../../Assets/logo.png";

const Navbar = () => {
   return (
      <Nav className="navbar justify-content-between py-4">
         <div className="ms-3">
            <img src={logo} className="logo"></img>
         </div>
         <div className="pt-1 me-3">
            <NavLink className="nav-item ps-4" to="/">
               Home
            </NavLink>
            <NavLink className="nav-item ps-4" to="/demo">
               Demo
            </NavLink>
         </div>
      </Nav>
   );
};

export default Navbar;
