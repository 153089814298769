import React, { useState } from "react";
import axios from "axios";
import "./FirstStep.css";
import pdf from "../../Assets/PDF.png";

const FirstStep = ({ onUploadSuccess, onData }) => {
   const API = "https://api-comcheck-7f7s.onrender.com/app/scrapping/";
   const [selectedFile, setSelectedFile] = useState(null);
   const [selectedCode, setSelectedCode] = useState(2009);
   const [show, setShow] = useState(false);
   const [loading, setLoading] = useState(false);
   const [uploadProgress, setUploadProgress] = useState(0);
   const [fileError, setFileError] = useState("");
   const [data, setData] = useState(2009);

   const handleFileChange = async (event) => {
      const file = event.target.files[0];
      if (!file) {
         return;
      }

      if (file.type !== "application/pdf") {
         setFileError("Please choose a PDF file.");
         setSelectedFile(null);
      } else {
         setSelectedFile(file);
         setFileError("");
      }
   };

   const handleCode = async (event) => {
      const code = event.target.value;
      console.log(code);
      setSelectedCode(code);
      setData(code);
   };

   const handleFileUpload = () => {
      setLoading(true);
      console.log(data);
      onData(data);
      setShow(true);
      if (!selectedFile) {
         setFileError("Please choose a PDF file.");
         return;
      }

      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("standard", selectedCode);

      axios
         .post(API, formData, {
            headers: {
               accept: "application/json",
               "content-type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
               const progress = Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
               );
               setUploadProgress(progress);
            },
         })
         .then((response) => {
            console.log("File uploaded successfully:", response.data);
            onUploadSuccess();
            console.log(formData);
            setLoading(false);
         })
         .catch((error) => {
            console.error("Error uploading file:", error);
         });
   };

   const OPT = {
      "2009 IECC": 2009,
      "2018 IECC": 2018,
      "2020 IECC": 2020,
   };

   return (
      <div className="upload--content d-flex flex-column justify-content-center align-items-center w-100 position-relative">
         <div className="mt-0 mb-2">
            <div className="d-flex justify-content-center">
               <img
                  className="upload--img opacity-50"
                  src={pdf}
                  alt="PDF icon"
               />
            </div>
            {selectedFile && (
               <div id="fileSelectedText" className="red-text p-2 text-center">
                  Selected file: {selectedFile.name}
               </div>
            )}
            {fileError && <div className="red-text p-2">{fileError}</div>}
         </div>
         <div className="mb-3 mt-1">
            <div className="d-flex w-100">
               <label
                  className="d-flex align-items-center me-2"
                  htmlFor="select-label"
               >
                  Code:
               </label>
               <select
                  className="form-select"
                  onChange={(e) => handleCode(e)}
                  id="select-label"
               >
                  {Object.entries(OPT).map(([key, value]) => (
                     <option key={key} value={value}>
                        {key}
                     </option>
                  ))}
               </select>
            </div>
         </div>
         <div className="d-flex w-100 flex-column flex-md-row align-items-center justify-content-center">
            <div className="mx-2 my-auto choose--button d-flex flex-column">
               <label
                  className="upload--button d-flex rounded-pill justify-content-center align-items-center mb-3 mb-md-0"
                  htmlFor="fileInput"
               >
                  Choose a PDF
               </label>
               <input
                  type="file"
                  onChange={handleFileChange}
                  id="fileInput"
                  accept="application/pdf"
                  className="p-0 m-0"
               />
            </div>
            <button
               className="upload--button __submit rounded-pill mx-2 mb-3 mb-md-0"
               onClick={handleFileUpload}
            >
               Upload PDF
            </button>
         </div>
         {selectedFile && show && (
            <div className="d-flex flex-column align-items-center red-text mt-md-3">
               <p>Uploading: {uploadProgress}%</p>
               <section className="upload--progress rounded-pill overflow-hidden">
                  <progress value={uploadProgress} max="100" />
               </section>
            </div>
         )}

         {loading && (
            <div className="d-flex my-3">
               <div className="spinner-border text-light" role="status">
                  <span className="visually-hidden">Loading...</span>
               </div>
            </div>
         )}
      </div>
   );
};

export default FirstStep;
