import Hero from "../../Components/Hero/Hero";
import Layout from "../../Components/Layout";

const Home = () => {
   return (
      <Layout>
         <Hero></Hero>
      </Layout>
   );
};

export default Home;
