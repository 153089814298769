import Navbar from "./Navbar/Navbar";

const Layout = ({ children }) => {
   return (
      <div className="container">
         <Navbar></Navbar>
         {children}
      </div>
   );
};

export default Layout;
