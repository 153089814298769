import React, { useState } from "react";
import FirstStep from "../../Components/FirstStep/FirstStep";
import Layout from "../../Components/Layout";
import SecondStep from "../../Components/SecondStep/SecondStep";
import ThirdStep from "../../Components/ThirdStep/ThirdStep";
import "./Demo.css";
import FourthStep from "../../Components/FourthStep/FourthStep";

const Demo = () => {
   const [currentStep, setCurrentStep] = useState(1);
   const [fileUploaded, setFileUploaded] = useState(false);
   const [data, setData] = useState(null);

   const handleData = (dataFromChild) => {
      setData(dataFromChild);
   };

   const handleNextClick = () => {
      if (currentStep === 1 && fileUploaded) {
         setCurrentStep(2);
         setFileUploaded(false);
      } else if (currentStep === 2) {
         setCurrentStep(3);
         setFileUploaded(false);
      } else if (currentStep === 3) {
         setCurrentStep(4);
      }
   };

   return (
      <Layout>
         <main className="demo--container w-100 overflow-auto position-relative mb-5">
            <p className="d-flex justify-content-center align-items-center py-2 m-0 fw-lighter">
               Comcheck Accelerator
            </p>
            <hr className="m-0" />

            {currentStep === 1 && (
               <FirstStep
                  onUploadSuccess={() => setFileUploaded(true)}
                  onData={handleData}
               />
            )}

            {currentStep === 2 && (
               <div className="second-step-container">
                  <SecondStep
                     onUploadSuccess={() => setFileUploaded(true)}
                     onData={data}
                  />
               </div>
            )}

            {currentStep === 3 && (
               <ThirdStep onUploadSuccess={() => setFileUploaded(true)} />
            )}
            {currentStep === 4 && <FourthStep />}

            {fileUploaded && currentStep !== 4 && (
               <div className="d-flex justify-content-md-end justify-content-center me-md-3">
                  <button
                     onClick={handleNextClick}
                     className="demo--btn rounded-pill px-5 fw-bolder mb-5 me-md-5 mt-md-0 mt-4"
                  >
                     Next »
                  </button>
               </div>
            )}
         </main>
      </Layout>
   );
};

export default Demo;
