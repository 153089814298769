import { useEffect, useState } from "react";
import axios from "axios";
import "./ThirdStep.css";

const ThirdStep = ({ onUploadSuccess }) => {
   const API_POST = "https://api-comcheck-7f7s.onrender.com/app/user-inputs/";
   const [userData, setUserData] = useState({
      is_residential: true,
      radio_btns: {
         floor: 0,
         roof: 0,
         ext_wall: 0,
         window_col1: 0,
         window_col2: 0,
         door: 0,
      },
      is_swinging_door: true,
      window_area: [0, 0],
      r_values: {
         floor: [0, 0],
         roof: [0, 0],
         ext_wall: [0, 0],
         window: [0, 0],
      },
   });

   const handleInputChange = (event) => {
      const { name, value, type, checked } = event.target;

      // Special handling for the "is_residential" checkbox
      if (name === "is_residential") {
         setUserData((prevData) => ({
            ...prevData,
            is_residential: checked,
         }));
      } else {
         setUserData((prevData) => ({
            ...prevData,
            radio_btns: {
               ...prevData.radio_btns,
               [name]: parseInt(value),
            },
         }));
      }
   };

   const postUserDataToAPI = () => {
      console.log("Data to be sent to API:", JSON.stringify(userData));

      axios
         .post(API_POST, userData, {
            headers: {
               "Content-Type": "application/json",
            },
         })
         .then((response) => {
            console.log("Data posted successfully:", response.data);
            onUploadSuccess();
         })
         .catch((error) => console.error("Error posting data:", error));
   };

   const handleSubmit = (event) => {
      event.preventDefault();
      postUserDataToAPI();
   };

   return (
      <div className="m-md-5 mx-4 mt-4 mb-3">
         <form className="row fs-5">
            <div className="col-md-6">
               <p className="caption mt-1">
                  Please fill in the following fields. Note that each of the
                  R-Values field must be a number between 0 – 60.
               </p>
               <div className="form-group mt-4">
                  <label htmlFor="is_residential">Is it Residential?</label>
                  <input
                     id="is_residential"
                     type="checkbox"
                     name="is_residential"
                     checked={userData.is_residential}
                     onChange={handleInputChange}
                     className="ms-0"
                  />
               </div>
               <div className="form-group mt-2">
                  <label>Floor:</label>
                  <div className="form-check">
                     <input
                        id="floor1"
                        type="radio"
                        className="form-check-input"
                        name="floor"
                        value="1"
                        checked={userData.radio_btns.floor === 1}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="floor1" className="form-check-label">
                        Wood-Framed
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="floor2"
                        type="radio"
                        className="form-check-input"
                        name="floor"
                        value="2"
                        checked={userData.radio_btns.floor === 2}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="floor2" className="form-check-label">
                        Steel Joist
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="floor3"
                        type="radio"
                        className="form-check-input"
                        name="floor"
                        value="3"
                        checked={userData.radio_btns.floor === 3}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="floor3" className="form-check-label">
                        Concrete Floor (over unconditioned space)
                     </label>
                  </div>
               </div>

               <div className="form-group mt-2">
                  <label>Roof:</label>
                  <div className="form-check">
                     <input
                        id="roof1"
                        type="radio"
                        className="form-check-input"
                        name="roof"
                        value="1"
                        checked={userData.radio_btns.roof === 1}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="roof1" className="form-check-label">
                        Insulation Entirely Above Deck
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="roof2"
                        type="radio"
                        className="form-check-input"
                        name="roof"
                        value="2"
                        checked={userData.radio_btns.roof === 2}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="roof2" className="form-check-label">
                        Metal Building, Standing Seam
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="roof3"
                        type="radio"
                        className="form-check-input"
                        name="roof"
                        value="3"
                        checked={userData.radio_btns.roof === 3}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="roof3" className="form-check-label">
                        Metal Building, Screw Down
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="roof4"
                        type="radio"
                        className="form-check-input"
                        name="roof"
                        value="4"
                        checked={userData.radio_btns.roof === 4}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="roof4" className="form-check-label">
                        Attic Roof, Wood Joists
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="roof5"
                        type="radio"
                        className="form-check-input"
                        name="roof"
                        value="5"
                        checked={userData.radio_btns.roof === 5}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="roof5" className="form-check-label">
                        Attic Roof, Steel Joists
                     </label>
                  </div>
               </div>
               <div className="form-group">
                  <label>Door:</label>
                  <div className="form-check">
                     <input
                        id="door1"
                        type="radio"
                        className="form-check-input"
                        name="door"
                        value="1"
                        checked={userData.radio_btns.door === 1}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="door1" className="form-check-label">
                        Uninsulated Single-Layer Metal
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="door2"
                        type="radio"
                        className="form-check-input"
                        name="door"
                        value="2"
                        checked={userData.radio_btns.door === 2}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="door2" className="form-check-label">
                        Uninsulated Double-Layer Metal
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="door3"
                        type="radio"
                        className="form-check-input"
                        name="door"
                        value="3"
                        checked={userData.radio_btns.door === 3}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="door3" className="form-check-label">
                        Insulated Metal
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="door4"
                        type="radio"
                        className="form-check-input"
                        name="door"
                        value="4"
                        checked={userData.radio_btns.door === 4}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="door4" className="form-check-label">
                        Wood
                     </label>
                  </div>
               </div>

               <div className="form-group mt-2">
                  <label>Exterior Wall:</label>
                  <div className="form-check">
                     <input
                        id="ext_wall1"
                        type="radio"
                        className="form-check-input"
                        name="ext_wall"
                        value="1"
                        checked={userData.radio_btns.ext_wall === 1}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="ext_wall1" className="form-check-label">
                        Wood-Framed, 16in. o.c.
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="ext_wall2"
                        type="radio"
                        className="form-check-input"
                        name="ext_wall"
                        value="2"
                        checked={userData.radio_btns.ext_wall === 2}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="ext_wall2" className="form-check-label">
                        Wood-Framed, 24in. o.c.
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="ext_wall3"
                        type="radio"
                        className="form-check-input"
                        name="ext_wall"
                        value="3"
                        checked={userData.radio_btns.ext_wall === 3}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="ext_wall3" className="form-check-label">
                        Steel-Framed, 16in. o.c.
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="ext_wall4"
                        type="radio"
                        className="form-check-input"
                        name="ext_wall"
                        value="4"
                        checked={userData.radio_btns.ext_wall === 4}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="ext_wall4" className="form-check-label">
                        Steel-Framed, 24in. o.c.
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="ext_wall5"
                        type="radio"
                        className="form-check-input"
                        name="ext_wall"
                        value="5"
                        checked={userData.radio_btns.ext_wall === 5}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="ext_wall5" className="form-check-label">
                        Metal Building Wall
                     </label>
                  </div>
               </div>
            </div>

            <div className="col-md-6">
               <div className="form-group">
                  <label>Window Column (1):</label>
                  <div className="form-check">
                     <input
                        id="window_col11"
                        type="radio"
                        className="form-check-input"
                        name="window_col1"
                        value="1"
                        checked={userData.radio_btns.window_col1 === 1}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="window_col11" className="form-check-label">
                        Metal Frame
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="window_col12"
                        type="radio"
                        className="form-check-input"
                        name="window_col1"
                        value="2"
                        checked={userData.radio_btns.window_col1 === 2}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="window_col12" className="form-check-label">
                        Metal Frame w/ Thermal Break
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="window_col13"
                        type="radio"
                        className="form-check-input"
                        name="window_col1"
                        value="3"
                        checked={userData.radio_btns.window_col1 === 3}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="window_col13" className="form-check-label">
                        Wood Frame
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="window_col14"
                        type="radio"
                        className="form-check-input"
                        name="window_col1"
                        value="4"
                        checked={userData.radio_btns.window_col1 === 4}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="window_col14" className="form-check-label">
                        Vinyl Frame
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="window_col15"
                        type="radio"
                        className="form-check-input"
                        name="window_col1"
                        value="5"
                        checked={userData.radio_btns.window_col1 === 5}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="window_col15" className="form-check-label">
                        Curtain Wall
                     </label>
                  </div>
               </div>

               <div className="form-group mt-2">
                  <label>Window Column (2):</label>
                  <div className="form-check">
                     <input
                        id="window_col21"
                        type="radio"
                        className="form-check-input"
                        name="window_col2"
                        value="1"
                        checked={userData.radio_btns.window_col2 === 1}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="window_col21" className="form-check-label">
                        Metal Frame
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="window_col22"
                        type="radio"
                        className="form-check-input"
                        name="window_col2"
                        value="2"
                        checked={userData.radio_btns.window_col2 === 2}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="window_col22" className="form-check-label">
                        Metal Frame w/ Thermal Break
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="window_col23"
                        type="radio"
                        className="form-check-input"
                        name="window_col2"
                        value="3"
                        checked={userData.radio_btns.window_col2 === 3}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="window_col23" className="form-check-label">
                        Wood Frame
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="window_col24"
                        type="radio"
                        className="form-check-input"
                        name="window_col2"
                        value="4"
                        checked={userData.radio_btns.window_col2 === 4}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="window_col24" className="form-check-label">
                        Vinyl Frame
                     </label>
                  </div>
                  <div className="form-check">
                     <input
                        id="window_col25"
                        type="radio"
                        className="form-check-input"
                        name="window_col2"
                        value="5"
                        checked={userData.radio_btns.window_col2 === 5}
                        onChange={handleInputChange}
                     />
                     <label htmlFor="window_col25" className="form-check-label">
                        Curtain Wall
                     </label>
                  </div>
               </div>

               <div className="form-group mt-2">
                  <label htmlFor="">Is it a swinging door?</label>
                  <input
                     id=""
                     type="checkbox"
                     name="is_swinging_door"
                     checked={userData.is_swinging_door}
                     onChange={handleInputChange}
                     className="ms-0"
                  />
               </div>

               <div className="form-group mt-2">
                  <label htmlFor="">Window Area (1):</label>
                  <input
                     id=""
                     type="number"
                     name="window_area_min"
                     value={userData.window_area[0]}
                     onChange={(event) =>
                        setUserData((prevData) => ({
                           ...prevData,
                           window_area: [
                              parseInt(event.target.value),
                              prevData.window_area[1],
                           ],
                        }))
                     }
                  />
               </div>
               <div className="form-group">
                  <label htmlFor="">Window Area (2):</label>
                  <input
                     id=""
                     type="number"
                     name="window_area_max"
                     value={userData.window_area[1]}
                     onChange={(event) =>
                        setUserData((prevData) => ({
                           ...prevData,
                           window_area: [
                              prevData.window_area[0],
                              parseInt(event.target.value),
                           ],
                        }))
                     }
                  />
               </div>

               <div className="form-group">
                  <label htmlFor="">Floor R-Values (Cavity):</label>
                  <input
                     id=""
                     type="number"
                     name="floor_r_min"
                     value={userData.r_values.floor[0]}
                     onChange={(event) =>
                        setUserData((prevData) => ({
                           ...prevData,
                           r_values: {
                              ...prevData.r_values,
                              floor: [
                                 parseInt(event.target.value),
                                 prevData.r_values.floor[1],
                              ],
                           },
                        }))
                     }
                  />
               </div>
               <div className="form-group">
                  <label htmlFor="">Floor R-Values (Continuous):</label>
                  <input
                     id=""
                     type="number"
                     name="floor_r_max"
                     value={userData.r_values.floor[1]}
                     onChange={(event) =>
                        setUserData((prevData) => ({
                           ...prevData,
                           r_values: {
                              ...prevData.r_values,
                              floor: [
                                 prevData.r_values.floor[0],
                                 parseInt(event.target.value),
                              ],
                           },
                        }))
                     }
                  />
               </div>

               <div className="form-group">
                  <label htmlFor="">Roof R-Values (Cavity):</label>
                  <input
                     id=""
                     type="number"
                     name="roof_r_min"
                     value={userData.r_values.roof[0]}
                     onChange={(event) =>
                        setUserData((prevData) => ({
                           ...prevData,
                           r_values: {
                              ...prevData.r_values,
                              roof: [
                                 parseInt(event.target.value),
                                 prevData.r_values.roof[1],
                              ],
                           },
                        }))
                     }
                  />
               </div>
               <div className="form-group">
                  <label htmlFor="">Roof R-Values (Continuous):</label>
                  <input
                     id=""
                     type="number"
                     name="roof_r_max"
                     value={userData.r_values.roof[1]}
                     onChange={(event) =>
                        setUserData((prevData) => ({
                           ...prevData,
                           r_values: {
                              ...prevData.r_values,
                              roof: [
                                 prevData.r_values.roof[0],
                                 parseInt(event.target.value),
                              ],
                           },
                        }))
                     }
                  />
               </div>

               <div className="form-group">
                  <label htmlFor="">Exterior Wall R-Values (Cavity):</label>
                  <input
                     id=""
                     type="number"
                     name="ext_wall_r_min"
                     value={userData.r_values.ext_wall[0]}
                     onChange={(event) =>
                        setUserData((prevData) => ({
                           ...prevData,
                           r_values: {
                              ...prevData.r_values,
                              ext_wall: [
                                 parseInt(event.target.value),
                                 prevData.r_values.ext_wall[1],
                              ],
                           },
                        }))
                     }
                  />
               </div>
               <div className="form-group">
                  <label htmlFor="">Exterior Wall R-Values (Continuous):</label>
                  <input
                     id=""
                     type="number"
                     name="ext_wall_r_max"
                     value={userData.r_values.ext_wall[1]}
                     onChange={(event) =>
                        setUserData((prevData) => ({
                           ...prevData,
                           r_values: {
                              ...prevData.r_values,
                              ext_wall: [
                                 prevData.r_values.ext_wall[0],
                                 parseInt(event.target.value),
                              ],
                           },
                        }))
                     }
                  />
               </div>

               <div className="form-group">
                  <label htmlFor="">Window R-Values (Cavity):</label>
                  <input
                     id=""
                     type="number"
                     name="window_r_min"
                     value={userData.r_values.window[0]}
                     onChange={(event) =>
                        setUserData((prevData) => ({
                           ...prevData,
                           r_values: {
                              ...prevData.r_values,
                              window: [
                                 parseInt(event.target.value),
                                 prevData.r_values.window[1],
                              ],
                           },
                        }))
                     }
                  />
               </div>
               <div className="form-group">
                  <label htmlFor="">Window R-Values (Continuous):</label>
                  <input
                     id=""
                     type="number"
                     name="window_r_max"
                     value={userData.r_values.window[1]}
                     onChange={(event) =>
                        setUserData((prevData) => ({
                           ...prevData,
                           r_values: {
                              ...prevData.r_values,
                              window: [
                                 prevData.r_values.window[0],
                                 parseInt(event.target.value),
                              ],
                           },
                        }))
                     }
                  />
               </div>
            </div>
            <div className="d-flex justify-content-center">
               <button
                  type="submit"
                  onClick={handleSubmit}
                  className="s-step--button rounded-pill px-5 mt-4"
               >
                  Save Data
               </button>
            </div>
         </form>
      </div>
   );
};

export default ThirdStep;
