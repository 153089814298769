import React, { useEffect, useState } from "react";
import axios from "axios";
import "./SecondStep.css";

const SecondStep = ({ onUploadSuccess, onData }) => {
   const API_GET = "https://api-comcheck-7f7s.onrender.com/app/building-area/";
   const API_POST =
      "https://api-comcheck-7f7s.onrender.com/app/building-area/update-choice/";
   const code = onData;
   const [isValidSelection, setIsValidSelection] = useState(true);
   const [errorMessage, setErrorMessage] = useState("");

   const OPTION_VALUES = {
      "Select Area Category...": 0,
      "Automotive Facility": 1,
      "Convention Center": 2,
      "Court House": 3,
      "Dining: Bar Lounge/Leisure": 4,
      "Dining: Cafeteria/Fast Food": 5,
      "Dining: Family": 6,
      Dormitory: 7,
      "Exercise Center": 8,
      Gymnasium: 9,
      "Healthcare-Clinic": 10,
      Hospital: 11,
      Hotel: 12,
      Library: 13,
      "Manufacturing Facility": 14,
      Motel: 15,
      "Motion Picture Theater": 16,
      Multifamily: 17,
      Museum: 18,
      Office: 19,
      "Parking Garage": 20,
      Penitentiary: 21,
      "Performing Arts Theater": 22,
      "Police/Fire Station": 23,
      "Post Office": 24,
      "Religious Building": 25,
      Retail: 26,
      "School/University": 27,
      "Sports Arena": 28,
      "Town Hall": 29,
      Transportation: 30,
      Warehouse: 31,
      Workshop: 32,
   };

   const OPTION_VALUES2 = {
      "Select Area Category...": 0,
      "Automotive facility": 1,
      "Convention center": 2,
      "Court House": 3,
      "Dining: Bar Lounge/Leisure": 4,
      "Dining: Cafeteria/Fast Food": 5,
      "Dining: Family": 6,
      Dormitory: 7,
      "Exercise Center": 8,
      "Fire Station": 9,
      Gymnasium: 10,
      "Health Care-Clinic": 11,
      Hospital: 12,
      Hotel: 13,
      Library: 14,
      "Manufacturing Facility": 15,
      Motel: 16,
      "Motion Picture Theater": 17,
      Multifamily: 18,
      Museum: 19,
      Office: 20,
      "Parking Garage": 21,
      Penitentiary: 22,
      "Performing Arts Theater": 23,
      Police: 24,
      "Post Office": 25,
      "Religious Building": 26,
      Retail: 27,
      "School/University": 28,
      "Sports Arena": 29,
      "Town Hall": 30,
      Transportation: 31,
      Warehouse: 32,
      Workshop: 33,
   };

   const [data, setData] = useState([]);
   const [selectedOptions, setSelectedOptions] = useState([]);

   useEffect(() => {
      const hasInvalidSelection = selectedOptions.some(
         (option) => option === 0
      );
      setIsValidSelection(!hasInvalidSelection);
   }, [selectedOptions]);

   useEffect(() => {
      axios
         .get(API_GET)
         .then((response) => {
            setData(response.data);
            setSelectedOptions(Array(response.data.length).fill(0));
         })
         .catch((error) => {
            console.error("Error fetching data:", error);
         });
   }, []);

   const handleOptionChange = (index, value) => {
      setSelectedOptions((prevOptions) => {
         const updatedOptions = [...prevOptions];
         updatedOptions[index] = OPTION_VALUES[value];
         return updatedOptions;
      });

      setData((prevData) => {
         const updatedData = [...prevData];
         updatedData[index].choice = OPTION_VALUES[value];
         return updatedData;
      });
   };

   const handleSubmit = () => {
      if (!isValidSelection) {
         setErrorMessage(
            "Please select an option for each item before submitting."
         );
         return;
      }

      setErrorMessage("");

      console.log("Data to be sent to API:", JSON.stringify(data));
      const updatedData = data.map((item, index) => ({
         title: item.title,
         choice: selectedOptions[index] || 0,
      }));

      axios
         .post(API_POST, updatedData)
         .then((response) => {
            console.log("Data updated successfully:", response.data);
            onUploadSuccess();
         })
         .catch((error) => {
            console.error("Error updating data:", error);
         });
   };

   const optionValues =
      code === "2020" || code === "2018" ? OPTION_VALUES2 : OPTION_VALUES;

   return (
      <>
         <div className="m-md-5 mt-4 mb-3 secondGrid">
            {data &&
               data.map((item, index) => (
                  <div key={index} className="mx-3 my-0">
                     <p className="red-text fs-5">{item.title}</p>
                     <select
                        className="form-select"
                        value={
                           Object.keys(optionValues)[
                              selectedOptions[index] || 0
                           ]
                        }
                        onChange={(e) =>
                           handleOptionChange(index, e.target.value)
                        }
                     >
                        {Object.keys(optionValues).map((optionLabel) => (
                           <option key={optionLabel} value={optionLabel}>
                              {optionLabel}
                           </option>
                        ))}
                     </select>
                  </div>
               ))}
         </div>
         <p className="d-flex justify-content-center text-warning">
            {errorMessage}
         </p>
         <div className="d-flex justify-content-center">
            <button
               onClick={handleSubmit}
               className="s-step--button rounded-pill px-5 mt-3 mb-4"
            >
               Submit
            </button>
         </div>
      </>
   );
};

export default SecondStep;
