import "./Hero.css";
import hero from "../../Assets/hero.png";
import { Link } from "react-router-dom";

const Hero = () => {
   return (
      <div className="hero mt-5 d-flex align-items-center">
         <section className="hero--content p-5">
            <div className="p-3 mt-2">
               <h1>Tired of wasting hours on manual Comcheck calculations?</h1>
               <h6 className="mt-4 mb-1 fw-light">
                  Try our state-of-the-art algorithm and revolutionize your
                  energy code compliance process with swift, automated
                  efficiency!
               </h6>
               <Link to="/demo">
                  <h6 className="hero--button mt-4 w-100 d-flex justify-content-center align-items-center">
                     Try It Now
                  </h6>
               </Link>
            </div>
         </section>
         <section className="hero--subcontainer w-100">
            <img src={hero} />
         </section>
      </div>
   );
};

export default Hero;
