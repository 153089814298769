import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Suspense } from "react";
import Home from "./Pages/Home/Home";
import Demo from "./Pages/Demo/Demo";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
   return (
      <BrowserRouter>
         {/* <Suspense > */}
         <Routes>
            <Route exact path="/demo" name="Demo" element={<Demo />} />
            <Route path="/home" name="Home" element={<Home />} />

            <Route path="*" name="Home" element={<Home />} />
         </Routes>
         {/* </Suspense> */}
      </BrowserRouter>
   );
}

export default App;
